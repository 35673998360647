//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SearchRow from '~/modules/TablePageParts/components/SearchRow.vue'

export default {
  components: { SearchRow },
  inheritAttrs: false,
  props: {
    height: {
      type: Number,
      default: null,
    },

    rostType: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'transparent', 'white'].includes(value)
      },
    },
    items: {
      type: Array,
      default: () => [],
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    repository: {
      default: null,
      type: Function,
    },
    isServerItemsLoad: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: [Boolean, String],
      default: false,
    },
    mobileViewHeader: {
      type: String,
      default: '',
    },
    loadItemsOnMount: {
      type: Boolean,
      default: false,
    },

    itemText: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    menuProps: {
      type: Object,
      default: () => ({}),
    },
    borderColor: {
      type: String,
      default: 'neutral-100',
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    prependItemSettings: {
      type: Object,
      default: () => null,
    },
    selectedType: {
      type: String,
      default: 'color',
      validator(value) {
        return ['color', 'check'].includes(value)
      },
    },
  },
  data() {
    return {
      search: '',
      isMobileViewOpen: false,
      loadedItems: [],
      isItemsLoaded: false,
      innerLoading: false,
      hackMenuProps: {
        value: false,
      },
    }
  },
  computed: {
    innerMenuProps() {
      return {
        ...this.hackMenuProps,
        ...this.menuProps,
      }
    },
    localHeight() {
      const defaultValue = this.$vuetify.breakpoint.smAndDown ? 40 : 48
      return 'multiple' in this.$attrs ? null : this.height || defaultValue
    },

    typeConfig() {
      const conf = {
        default: {
          'background-color': 'grey-lighter',
          color: 'primary',
        },
        transparent: {
          'background-color': 'transparent',
          class: 'icis-select__transparent',
        },
        white: {
          'background-color': 'white',
          class: 'icis-select__white',
        },
      }
      return conf[this.rostType]
    },

    bindedAttrs() {
      let attrs = {
        solo: true,
        flat: true,
        ...this.typeConfig,
        ...this.$attrs,
      }
      if (this.localHeight) {
        attrs = {
          ...attrs,
          class: [attrs.class, 'icis_disable-min-height'],
        }
      }

      if (this.$attrs['min-height']) {
        attrs = {
          ...attrs,
          style: {
            ...attrs.style,
            minHeight: this.$attrs['min-height'] + 'px',
          },
          class: [attrs.class, 'icis_min-height-inherit'],

          // class: [attrs.class, 'icis_disable-min-height'],
        }
      }

      return attrs
    },
    /*
    Если есть value но нет item, то сетим в item это значение.
    */
    localItems() {
      if (this.$attrs.value && !this.items.length && !this.loadedItems.length) {
        if (Array.isArray(this.$attrs.value)) {
          return [...this.$attrs.value]
        } else {
          return [this.$attrs.value]
        }
      } else {
        if (this.isServerItemsLoad) {
          return this.loadedItems
        }
        if (
          this.prependItemSettings &&
          this.prependItemSettings.isShowSearchField
        ) {
          return this.items.filter((item) =>
            item[this.itemText]
              .toLowerCase()
              .includes(this.search.toLowerCase())
          )
        }
        return this.items
      }
    },

    innerRules() {
      const rules = this.rules
      if (this.isRequired) {
        const validateFn = (value) => {
          if (!value) return 'Заполните поле'
          if ('multiple' in this.$attrs && value.length === 0)
            return 'Заполните поле'
          return true
        }
        rules.push(validateFn)
      }
      return rules
    },
  },
  watch: {
    search(newVal) {
      const time = 500
      if ((newVal && newVal.length >= 3) || !newVal || newVal.length === 0) {
        this.isItemsLoaded = false
        clearTimeout(time)
        setTimeout(() => this.loadAllItems(), 300)
      }
    },
  },
  async created() {
    if ('multiple' in this.$attrs && this.$attrs.height) {
      console.error(
        'Вы задали фиксированную высоту для Autocomplete при множественном выборе. Это плохо скажется на UI'
      )
    }
    if (this.loadItemsOnMount) {
      await this.loadAllItems()
    }
  },
  methods: {
    focusSearchRow() {
      this.$nextTick(() => {
        setTimeout(() => {
          const inputField =
            this.$refs.searchRow &&
            this.$refs.searchRow.$el.querySelector('input')
          if (inputField) {
            inputField.focus()
          }
        }, 100)
      })
    },
    onSelectClick() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.isMobileViewOpen = true
        this.onFocus()
      } else if (
        this.prependItemSettings &&
        this.prependItemSettings.isShowSearchField
      ) {
        this.focusSearchRow()
      }
    },
    async loadAllItems() {
      if (this.isItemsLoaded) {
        return
      }
      this.innerLoading = true
      try {
        const { data } = await this.repository({
          'p[per_page]': 100000,
          search: this.search.length >= 3 ? this.search : undefined,
        })

        this.loadedItems = data.items || data
        this.isItemsLoaded = true
      } catch (error) {
        console.error(error)
      } finally {
        this.innerLoading = false
      }
    },
    async onFocus() {
      if (this.isServerItemsLoad) await this.loadAllItems()
      this.hackMenuProps.value = true
      delete this.hackMenuProps.value
    },
  },
}
